angular.module('app.controllers').controller 'HomeCtrl', [
  '$scope', '$rootScope', 'APIRead', 'APIChats', 'APIData', '$interval', 'UI', '$location', '$cookies', 'APIFeed', 'APIGallery'
].append (self, root, APIRead, APIChats, APIData, $interval, UI, $location, $cookies, APIFeed, APIGallery) ->

  self.loadSearch = false

  self.daysBefore = moment("2025-04-04").diff(moment(), 'days')

  self.search = ''

  self.saveForm = (form) ->
    self.sForm = form

  self.searchByTerm = (search = true) ->
    return if self.sForm?.$invalid

    self.loadSearch = true
    
    return $location.path("/suche").search({q: self.search}) if search

        
  # Initialize mute toggle functionality
  angular.element(document).ready ->
    muteToggle = angular.element('#mute-toggle')
    if muteToggle.length > 0
      muteToggle.on 'click', ->
        bgVideo = angular.element('#bg-video')[0]
        if bgVideo
          bgVideo.loop = !bgVideo.loop
          bgVideo.muted = !bgVideo.muted
          # Toggle the "muted" class based on video mute state
          if bgVideo.muted
            muteToggle.addClass('muted')
          else
            muteToggle.removeClass('muted')
  
  APIRead.query {entry_type: 'novelties', full: true, random: true, limit: 6, with_image: true, splice: true, categorie: '2|3|4'}, (data) ->
    self.novelties = data
  
  APIFeed.get {}, (data) ->
    self.feed = data

  APIData.query {slug: 'partner'}, (data) ->
    self.partners = data


  #
  # Slider settings
  #


  self.moreRandomExhibitors =
    infinite: true
    dots: true
    slidesToShow: 5
    slidesToScroll: 2
    arrows: true
    prevArrow: '<button type="button" class="slick-arrow slick-prev"><svg class="icon icon_30"><use xlink:href="assets/img/sprite.svg#icon-chevron-left"></use></svg></button>'
    nextArrow: '<button type="button" class="slick-arrow slick-next"><svg class="icon icon_30"><use xlink:href="assets/img/sprite.svg#icon-chevron-right"></use></svg></button>'

    responsive: [
      {
        breakpoint: 1280
        settings: 
          arrows: false
      }
      {
        breakpoint: 1023
        settings: 
          arrows: false
          slidesToShow: 4
      }
      {
        breakpoint: 568
        settings: 
          centerMode: false
          variableWidth: false
          slidesToShow: 2
          arrows: false
      }
    ]


  self.newsSlider =
    infinite: false
    arrows: false
    slidesToShow: 4
    slidesToScroll: 1
    speed: 500
    autoplay: false
    autoplaySpeed: 5000
    useCSS: false
    cssEase: 'linear'
    dots: false
    method: {}
    event: {}
    initialSlide: 1
    swipe: false
    draggable: false


    responsive: [
      {
        breakpoint: 1024
        settings: 
          slidesToShow: 3
          initialSlide: 0
          dots: true
          swipe: true
          draggable: true
          infinite: true
      }
      {
        breakpoint: 768
        settings: 
          slidesToShow: 2
          initialSlide: 0
          dots: true
          swipe: true
          draggable: true
          infinite: true
      }
      {
        breakpoint: 568
        settings: 
          slidesToShow: 1
          initialSlide: 0
          dots: true
          swipe: true
          draggable: true
          infinite: true
      }
    ]
